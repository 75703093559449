import { render, staticRenderFns } from "./addAthlete.vue?vue&type=template&id=63f1acbc&"
import script from "./addAthlete.vue?vue&type=script&lang=js&"
export * from "./addAthlete.vue?vue&type=script&lang=js&"
import style0 from "./addAthlete.vue?vue&type=style&index=0&id=63f1acbc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_f3ovp5syquuyqzyjytk6i7vpxq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports