<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import DatePicker from 'vue2-datepicker'
import {mapState} from "vuex";
export default {

    page: {title: 'Προσθήκη πελάτη'},

    components: { DatePicker, FormWizard, TabContent },
    data(){
        return{
           athlete: {
                firstName: '',
                lastName: '',
                sex: null,
                birthDate: '',
                phoneNum: '',
                mobileNum: '',
                email: '',
                occupation: '',
                address: '',
                city: '',
                zipCode: '',
                notes: '',
                sessionCost: 0,
                measurements: {
                    weight: null,
                    height: null,
                    bmi: null,
                    fatPercentage: null
                },

            },

            shortQuestionnaire: {
                q1: new Date(),
                q2: null,
                q3: null,
                q4: null,
                q5: null,
                q6: null,
                q7: null,
                q8 :null,
                q9: null,
                q10     : null,
                q11     : null,
                q12     : null,
                q13     : null, q13_1     : null,
                q14     : null, q14_1     : null,
                q15     : null, q15_1     : null,
                q16     : null, q16_1     : null,
                q17     : null, q17_1     : null,
                q18     : null, q18_1     : null,
                q19     : null, q19_1     : null,
                q20     : null, q20_1     : null,
                q21     : null, q21_1     : null,
                q22     : null, q22_1     : null,
                q23     : null, q23_1     : null,
                q24     : null, q24_1     : null,
                q25     : null, q25_1     : null,
                q26     : null, q26_1     : null,
                q27     : null,
                q28    : null,
                q29     : null,
                q30     : null,
                q31     : null,
                q32     : null,
                q33     : null,
                q34     : null,
                q35     : null,
            },

            error: null,
            shortQuestionnaireTabId: 0,
            isCreatingAthlete: false
        }
    },
    computed:{
        ...mapState({
          shortQuestionnaireStructure: state => state.athlete._shortQuestionnaireStructure
        }),
        validateSex(){
            return this.athlete.sex === 'M' || this.athlete.sex === 'F';
        },

        validateWeight(){
            return !this.athlete.weight || this.athlete.weight > 0;
        },

        validateHeight(){
            return !this.athlete.height ||  this.athlete.height > 0;
        },

        validateFat(){
            return this.athlete.fat > 0 ;
        },

        validateBirthDate(){
            return this.athlete.birthDate !== '' && this.athlete.birthDate !== null;
        },

        validateName(){
            return this.athlete.firstName !== null && this.athlete.firstName !== '';
        },
        validateLastName(){
            return this.athlete.lastName !== null && this.athlete.lastName !== '';
        }

    },
    methods: {
        shouldEnable(value){
            return value === '1' || value === 1;
        },

        createAthlete(){
            if(this.isCreatingAthlete) return;
            this.isCreatingAthlete = true;
            this.athlete.birthDate = new Date(this.athlete.birthDate).setHours(12);
            this.athlete.athleteShortQuestionnaire = this.shortQuestionnaire;
            this.athlete.email = this.athlete.email.replaceAll(' ', '').toLowerCase();
            this.$axios.post(`/athlete/`, this.athlete).then(result=>{

                this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Ο πελάτης δημιουργήθηκε επιτυχώς!'});
                this.$router.push('/athletes');

            }).catch( e => {
                this.isCreatingAthlete = false;
                this.$notify({group: 'athlisis-notifications', type:'error',title: 'Αποτυχία!', text: 'Η δημιουργία του πελάτη απέτυχε!!'});

            });
        },

        validateReqUserInfo(){
            this.error = null;
            if(this.athlete.firstName === '' || this.athlete.lastName === '' || this.athlete.sex === null || this.athlete.birthDate === ''){
                this.error = 1;
                return false;
            }
            return true;
        }
    }
}
</script>
<template>
    <div id="add-athlete-layout">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                  <b-card-title>
                    Φόρμα δημιουργίας πελάτη
                  </b-card-title>
                    <div class="card-body">

                        <form-wizard back-button-text="Πίσω" next-button-text="Επόμενο" finish-button-text="Δημιουργία" color="#3155A6" error-color="#dc3545" @on-complete="createAthlete">

                            <tab-content title="Βασικά στοιχεία" icon="icon-user" :before-change="validateReqUserInfo">
                                <div class="row">
                                    <div class="col-md-6">
                                        <b-form-group id="input-firstName" label="Όνομα*" label-for="firstName" >
                                            <b-form-input id="firstName" v-model="athlete.firstName" type="text" placeholder="Εισάγετε το όνομα του πελάτη" required></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-6">
                                        <b-form-group id="input-lastName" label="Επώνυμο*" label-for="lastName">
                                            <b-form-input id="lastName" v-model="athlete.lastName" type="text" placeholder="Εισάγετε το επώνυμο του πελάτη" required></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <b-form-group id="input-birthDate" label="Ημερομηνία Γέννησης*" label-for="birthDate">
                                            <date-picker v-model="athlete.birthDate"  :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY" placeholder="Ημέρα/μήνας/έτος"></date-picker>
                                        </b-form-group>
                                    </div>

                                    <div class="col-md-4">
                                        <b-form-group id="input-gender" label="Φύλο*" label-for="gender">
                                            <b-form-select v-model="athlete.sex" required>
                                                <option value="null">Επιλέξτε φύλο: </option>
                                                <option value="M">Άρρεν</option>
                                                <option value="F">Θήλυ</option>
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                  <div class="col-md-4">
                                    <label>Κόστος συνεδρίας:</label>
                                    <b-input-group append="€" >
                                      <b-form-input type="number" step="1" v-model="athlete.sessionCost" placeholder="(π.χ. 50)"/>
                                    </b-input-group>
                                  </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <b-form-group id="input-email" label="E-mail" label-for="email" >
                                            <b-form-input id="email" v-model="athlete.email" type="text" @change="athlete.email = athlete.email.replaceAll(' ','').toLowerCase()"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-group id="input-mobileNum" label="Κινητό τηλέφωνο" label-for="mobileNum">
                                            <b-form-input id="mobileNum" v-model="athlete.mobileNum" type="text"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-group id="input-phoneNum" label="Σταθερό τηλέφωνο" label-for="phoneNum" >
                                            <b-form-input id="phoneNum" v-model="athlete.phoneNum" type="text"></b-form-input>
                                        </b-form-group>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <b-form-group id="input-address" label="Διεύθυνση" label-for="address">
                                            <b-form-input id="address" v-model="athlete.address" type="text"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-group id="input-city" label="Πόλη" label-for="city" >
                                            <b-form-input id="city" v-model="athlete.city" type="text"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-group id="input-zipCode" label="Ταχυδρομικός Κώδικας" label-for="zipCode" >
                                            <b-form-input id="zipCode" v-model="athlete.zipCode" type="text"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">

                                </div>

                                <b-alert v-if="error" show variant="danger">Προσοχή! Συμπληρώστε όλα τα απαραίτητα πεδία!</b-alert>

                            </tab-content>

                            <tab-content title="Σύντομο ερωτηματολόγιο" icon="icon-reload">
                                <b-tabs v-model="shortQuestionnaireTabId" class="short-questionnaire" fill>
                                  <b-tab v-for="(tab,index) in shortQuestionnaireStructure.tabs" :key="tab" :title="shortQuestionnaireStructure[tab].title">
                                    <div v-if="shortQuestionnaireTabId === index">
                                      <div class="card-body">
                                        <h4 class="mb-3 bg-light p-2">{{ shortQuestionnaireStructure[tab].title }} </h4>

                                        <div v-for="question in shortQuestionnaireStructure[tab].questions" :key="question.key">
                                          <b-row v-if="question.type !== 'notice'" class="my-1">
                                            <b-col sm="3">
                                              <label :for="'sq-'+question.key">{{question.question}}</label>
                                            </b-col>
                                            <b-col sm="7">

                                              <b-form-group v-if="question.type === 'date'" :id="'sq-'+question.key" :label-for="'sq-'+question.key">
                                                <date-picker v-model="shortQuestionnaire[question.key]" :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY"></date-picker>
                                              </b-form-group>

                                              <b-form-radio-group v-if="question.type === 'YesNo'" :id="'sq-'+question.key"
                                                                  v-model="shortQuestionnaire[question.key]">
                                                <b-form-radio value="1">Ναι</b-form-radio>
                                                <b-form-radio value="0">Όχι</b-form-radio>
                                              </b-form-radio-group>
                                              <b-form-radio-group v-if="question.type === 'Options'" :id="'sq-'+question.key"
                                                                  v-model="shortQuestionnaire[question.key]">
                                                <b-form-radio v-for="option in question.options" :key="option.value" :value="option.value">
                                                  {{ option.text }}</b-form-radio>
                                              </b-form-radio-group>
                                              <b-form-input v-if="question.type === 'text'" :id="'sq-'+question.key"
                                                            v-model="shortQuestionnaire[question.key]" type="text"></b-form-input>

                                              <b-form-textarea v-if="question.type === 'textarea'"
                                                               :id="'sq-'+question.key" v-model="shortQuestionnaire[question.key]"
                                                               type="textarea" :rows="question.rows"></b-form-textarea>

                                            </b-col>
                                          </b-row>

                                          <div v-if="question.type === 'notice'">
                                            <strong>{{question.text}}</strong>
                                          </div>

                                          <div v-if="question.subQuestions && (shouldEnable(shortQuestionnaire[question.key]) || question.type === 'Options')" class="questionnaire-inner-1">

                                            <div v-if="!subQuestion.value || parseInt(subQuestion.value) === parseInt(shortQuestionnaire[question.key])" v-for="subQuestion in question.subQuestions" :key="subQuestion.key">
                                              <b-row class="my-1 align-items-center">
                                                <b-col sm="2">
                                                  <label :for="'sq-'+subQuestion.key">{{ subQuestion.question }}</label>
                                                </b-col>
                                                <b-col sm="7">
                                                  <b-form-input v-if="subQuestion.type === 'text'" :id="'sq-'+subQuestion.key"
                                                                v-model="shortQuestionnaire[subQuestion.key]" type="text"></b-form-input>

                                                  <b-form-textarea v-if="subQuestion.type === 'textarea'"
                                                                   :id="'sq-'+subQuestion.key" v-model="shortQuestionnaire[subQuestion.key]"
                                                                   type="textarea" :rows="subQuestion.rows"></b-form-textarea>

                                                </b-col>
                                              </b-row>
                                            </div>

                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </b-tab>

                                </b-tabs>
                            </tab-content>

                            <tab-content title="Μετρήσεις" icon="icon-list">

                                <div class="row">
                                    <div class="col-md-4">
                                        <b-form-group id="input-weight" label="Βάρος" label-for="weight">
                                            <b-form-input id="weight" v-model="athlete.measurements.weight" type="text" placeholder="Εισάγετε το βάρος του πελάτη σε kg"
                                                          @input="athlete.measurements.weight = replaceCommaWithDot(athlete.measurements.weight)"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-group label="Ύψος:" label-for="edit-height">
                                            <b-input-group append="cm" >
                                                <b-form-input id="edit-height" v-model="athlete.measurements.height" type="text"
                                                               placeholder="Εισάγετε το ύψος του πελάτη σε cm "></b-form-input>
                                            </b-input-group>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-group label="Λίπος:" label-for="edit-fat">
                                            <b-input-group append="%" >
                                                <b-form-input id="edit-fat" v-model="athlete.measurements.fatPercentage" type="text"
                                                               placeholder="Εισάγετε το λίπος του πελάτη (π.χ. 10%)"
                                                              @input="athlete.measurements.fatPercentage = replaceCommaWithDot(athlete.measurements.fatPercentage)"></b-form-input>
                                            </b-input-group>
                                        </b-form-group>
                                    </div>
                                </div>
                            </tab-content>

                            <tab-content title="Σημείωσεις" icon="icon-check">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="text-center">
                                            <b-form-group id="input-notes" label="Σημειώσεις (προαιρετικό)" label-for="notes">
                                                <b-form-textarea id="notes" v-model="athlete.notes" type="textarea" placeholder="Εισάγετε ειδικές σημειώσεις για τον πελάτη" rows="6"></b-form-textarea>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>

                        </form-wizard>
                        <p class="text-muted">Τα πεδία με * είναι υποχρεωτικά</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .questionnaire-inner-1{
        margin-left:25px;
    }
    .questionnaire-inner-1 b-form-input{
        width:100%;
    }
    label{
        font-weight:normal;
    }
    .my-1{
        padding:10px 0;
    }
    .questionnaire-inner-1 .my-1{
        padding: initial;
    }
</style>
